<template>
    <h2>KAWASAKI: INFORMATION SYSTEMS INTERN</h2>
    <div class="content">
        <h1>JOB DESCRIPTION</h1><br>
        <p>The selected intern will be assigned a mentor and tasks or projects that require the knowledge acquired in the Information Systems sciences of software development, 
            database development, networking, web applications or similar programs. The expectation is to apply this knowledge to real life applications primarily for the benefit of the 
            intern’s education and experience as well as Kawasaki projects and processes. The intern will work with Visual LANSA, HTML, CSS, JavaScript, and SQL.</p><br>
        <h1>BRIEF</h1><br>
        <p>The purpose of this project is to provide Assembly Production with a means to package extra and loose parts prior to assembly of the engine, 
            validate the proper extra and loose parts are being packaged with the proper engine at crating and save traceability data.</p><br>
        <h1>VISUAL LANSA</h1><br>
        <p>The first thing that I did was learn Visual LANSA. Visual LANSA is a low-code system designed to create business programs with ease. This system is used by companies such as Porsche, Honda, Allianz, Visa, Kellogg's, and many more. 
            The cool thing about this program is that it is very easy to link to and work with databases as well as allowing HTML, CSS, and Javascript customization.</p><br>
        <h1>DATABASE DESIGN</h1><br>
        <p>After my training was done, I was tasked with creating the necessary database tables and fields in Visual LANSA. This was fairly straight forward, as my mentor had already laid out what had needed to be made.
            I still had to link the relations together, and that was done during the whole development process after a bunch of learning and tweaking. Here's an example of a table I was tasked with making:</p><br>
        <div class="imagecontainer">
            <div><img src="../assets/cap.png"></div>
        </div><br>
        <h1>WEB APP</h1><br>
        <p>After creating the database tables, I moved on to the meat of the project: the web app. This app is created for users on the manufacturing floor at Kawasaki. The user can select
            an engine that they want to package extra parts bags for and then they will enter a screen with the list of necessary parts. Once they have completed a bag, they will hit the "Print" button,
            which prints a label. The user then puts this label on the bag they packed, and the program will prompt them to scan a barcode on said label. This process completes until the order is completed.</p><br>
        <div class="imagecontainer wrap">
            <div><img src="../assets/k0.png"></div>
            <div><img src="../assets/k1.png"></div>
            <div><img src="../assets/k2.png"></div>
            <div><img src="../assets/k3.png"></div>
        </div><br>
        <h1>LABELS</h1><br>
        <p>After the web app was complete, it was time to design and code the labels that will be printed. This process was fun and frustrating at the same time. The program I used to create these labels is called Barcode400, which
            links up to the IBM i system that Kawasaki uses.</p><br>
        <div class="imagecontainer wrap">
            <div><img src="../assets/IMG-4871.jpg"></div>
            <div><img src="../assets/IMG-4872.jpg"></div>
        </div><br>
        <h1>WHAT DID I LEARN?</h1><br>
        <p>This internship helped me tremendously in learning how to learn a new programming language. Since Visual LANSA is not commonly used any more, there isn't a lot of documentation online
            for its programming language, RDML. This required me to use my pre-existing programming knowledge, the help of my colleagues, and RDML documentation to push past any hurdle in my way. I found
            it was a great way to build a relationship with the people I worked with, because I would have to go to them if I had a problem instead of Stack Overflow. Another thing that this internship helped me with was
            understanding the relationships of a database better. I had previously taken a database systems class, but to get real-world experience in creating new database tables is an entirely different level. </p><br>
    </div><br><br>
</template>